import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { RegisterSignal } from "./registersignal";
import { VEvent, SignalTag, SignalEvent } from "./vevent";
import { Moment } from "moment";
import { DoorEvent, CleanEvent } from "./doorevent";
import { map } from "rxjs/internal/operators/map";
import { catchError } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { ObservableAxios, observableAxios } from "../rxjs-axios";

@Injectable({
  providedIn: "root",
})
export class SignalsService {
  private readonly base_signal_url = environment.dependencies.signal_api.url;
  private readonly signals_url = this.base_signal_url + "/api/sevents/signals";
  private readonly sevents_url = this.base_signal_url + "/api/sevents";

  private readonly http: ObservableAxios = observableAxios;

  constructor() { }

  public getSignals(): Observable<Array<RegisterSignal>> {
    return this.http.get(`${this.signals_url}`).pipe(
      map((data: Array<any>) => {
        return data.map((day) => {
          return new RegisterSignal(
            day.id,
            day.type,
            day.sourceId,
            day.timestamp,
            day.unit,
            day.hubId,
            day.value,
            day.lastValue,
            day.unitCode
          );
        });
      })
    );
  }

  public getVEvents(sourceId: string): Observable<Array<VEvent>> {
    return this.http.get(`${this.sevents_url}?sourceId=${sourceId}`).pipe(
      map((data: Array<any>) => {
        return data.map((data) => {
          return new VEvent(
            data.id,
            data.type,
            data.sourceId,
            data.timestamp,
            data.value
          );
        });
      })
    );
  }
  public getTags(entityId: string): Observable<SignalTag[]> {
    console.log("Getting Tags");
    return this.http.get(`${this.sevents_url}/entity/tags/${entityId}`).pipe(
      map((data: Array<any>) => {
        return data.map((item: any) => {
          return SignalTag.deSerialize(item);
        });
      })
    );
  }
  public postSignalTag(signalTag: SignalTag): Observable<any> {
    return this.http.post(`${this.sevents_url}/tag`, signalTag).pipe(
      map((data: any) => {
        return SignalTag.deSerialize(data);
      })
    );
  }
  public getVEventsFromPeriod(
    sourceId: string,
    from: Moment,
    to: Moment
  ): Observable<Array<VEvent>> {
    var fromValue = from.toISOString();
    var toValue = to.toISOString();
    return this.http
      .get(
        `${this.sevents_url}?sourceId=${sourceId}&from=${fromValue}&to=${toValue}`
      )
      .pipe(
        map((data: Array<any>) => {
          return data.map((day) => {
            return new VEvent(
              day.id,
              day.type,
              day.sourceId,
              day.timestamp,
              day.value
            );
          });
        })
      );
  }

  public GetAllSignalsForEntities(
    entityIds: Array<string>
  ): Observable<Map<string, RegisterSignal[]>> {
    let idsParam = entityIds
      .reduce((acc, c) => {
        acc += `ids=${c}&`;
        return acc;
      }, "")
      .slice(0, -1);

    return this.http
      .get(`${this.sevents_url}/entities/signals?${idsParam}`)
      .pipe(
        catchError((err, caught) => {
          if (err?.response?.status == 404) {
            return of(new Array<string>());
          }
          return throwError(() => err);
        }),
        map((data: any) => {
          // var result = new Map<Guid,RegisterSignal>();
          var m = Object.keys(data).reduce((acc, k) => {
            var value = data[k];

            acc.set(
              k,
              value
                .map((item) => {
                  return new RegisterSignal(
                    item.id,
                    item.type,
                    item.sourceId,
                    item.timestamp,
                    item.unitCode,
                    item.hubId,
                    item.value,
                    item.lastValue,
                    item.unitCode
                  );
                })
                .sort(RegisterSignal.compareDesc)
            );
            return acc;
          }, new Map<string, RegisterSignal[]>());
          return m;
        })
      );
  }

  public GetSignalsForEntities(
    entityIds: Array<string>
  ): Observable<Map<string, RegisterSignal>> {
    var idsParam = entityIds
      .reduce((acc, c) => {
        acc += `ids=${c}&`;
        return acc;
      }, "")
      .slice(0, -1);

    console.log(idsParam);
    return this.http
      .get(`${this.sevents_url}/entities/signals?${idsParam}`)
      .pipe(
        catchError((err) => {
          console.log("No signals for entity");
          return of(new Array<string>());
        }),
        map((data: any) => {
          console.log("------Signals----", data);
          var m = Object.keys(data).reduce((acc, k) => {
            var value = data[k][0];
            acc.set(
              k,
              new RegisterSignal(
                value.id,
                value.type,
                value.sourceId,
                value.timestamp,
                value.unit,
                value.hubId,
                value.value,
                value.lastValue,
                value.unitCode
              )
            );
            return acc;
          }, new Map<string, RegisterSignal>());
          return m;
          // data.forEach((value,key)=>{
          //   result.set(key,new RegisterSignal(value.id,value.type,value.sourceId,value.timestamp,value.unit,value.hubId,value.value,value.lastValue));
          // });
          // return result;
        })
      );
  }

  public GetSignalsForEntity(
    entityId: string,
    from: Moment,
    to: Moment
  ): Observable<Array<VEvent>> {
    var fromValue = from.toISOString();
    var toValue = to.toISOString();
    return this.http
      .get(
        `${this.sevents_url}/entity/${entityId}?from=${fromValue}&to=${toValue}`
      )
      .pipe(
        catchError((err, caught) => {
          if (err.response.status == 404) {
            // Signals not found for entity,
            return of(new Array<VEvent>());
          }
          return throwError(() => err);
        }),
        map((data: any) => {
          return data.map((x) => {
            return VEvent.deSerialize(x);
          });
        })
      );
  }

  public GetDoorSignalsForEntity(
    entityId: string,
    from: Moment,
    to: Moment
  ): Observable<Array<SignalEvent>> {
    var fromValue = from.toISOString();
    var toValue = to.toISOString();
    return this.http
      .get(
        `${this.sevents_url}/entity/${entityId}?from=${fromValue}&to=${toValue}&eventType=clean`
      )
      .pipe(
        catchError((err, caught) => {
          if (err.response.status == 404) {
            // Signals not found for entity,
            return of(new Array<SignalEvent>());
          }
          return throwError(() => err);
        }),
        map((data: Array<SignalEvent>) => {
          return data.map((x) => {
            if (x.type === "DoorOpenCloseEvent") {
              return DoorEvent.deSerialize(x);
            } else if (x.type === "CleanEvent") {
              return CleanEvent.deSerialize(x);
            }
            console.log("Unhandled type for signal event:", x);
          });
        })
      );
  }

  public GetDoorEventsSinceLastClean(
    entityId: string
  ): Observable<Array<DoorEvent>> {
    return this.http
      .get(`${this.sevents_url}/entity/openclose/${entityId}`)
      .pipe(
        catchError((err, caught) => {
          if (err.response.status == 404) {
            // Signals not found for entity,
            return of(new Array<DoorEvent>());
          }
          return throwError(() => err);
        }),
        map((data: Array<DoorEvent>) => {
          return data.map((x) => {
            if (x.type === "DoorOpenCloseEvent") {
              return DoorEvent.deSerialize(x);
            }
            console.log("Unhandled type for signal event:", x);
          });
        })
      );
  }

  public GetLatestDoorEvent(entityId: string): Observable<DoorEvent> {
    return this.http
      .get(`${this.sevents_url}/entity/latest/${entityId}/DoorOpenCloseEvent`)
      .pipe(
        catchError((err, caught) => {
          if (err.response.status == 404) {
            // Signals not found for entity,
            return of(undefined);
          }
          return throwError(() => err);
        }),
        map((data: any) => {
          if (!data) return null;
          if (data.type === "DoorOpenCloseEvent") {
            return DoorEvent.deSerialize(data);
          }
          console.log("Unhandled type for signal event:", data);
        })
      );
  }

  public GetLatestCleanEvent(entityId: string): Observable<CleanEvent> {
    return this.http
      .get(`${this.sevents_url}/entity/latest/${entityId}/CleanEvent`)
      .pipe(
        catchError((err, caught) => {
          if (err.response.status == 404) {
            // Signals not found for entity,
            return of(undefined);
          }
          return throwError(() => err);
        }),
        map((data: any) => {
          if (!data) {
            return;
          }

          if (data.type === "CleanEvent") {
            return CleanEvent.deSerialize(data);
          }
          console.log("Unhandled type for signal event:", data);
        })
      );
  }

  public registerNewEntityCleanSignal(entityId: string): Observable<object> {
    return this.http.post(
      `${this.sevents_url}/entityCleanSignal/${entityId}`,
      undefined
    );
  }

  public postCleanEvent(cleanEvent: CleanEvent): Observable<any> {
    return this.http.post(
      `${this.sevents_url}/cleanEvent`,
      cleanEvent.restObject()
    );
  }
}
