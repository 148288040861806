import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  AlarmAggregate,
  EntitySiteMap,
} from "../services/siteAlarmInfoService";
import moment from "moment";
import { Entity } from "../../entity/entity";
import { Observable } from "rxjs/internal/Observable";
import { Site } from "../../entity/site";


type Props ={
  alarm: AlarmAggregate;
  site: Site;
  entity: Entity;
  filter: (entity: Entity) => Observable<boolean>;
}

export const EntityAlarmItem :React.FC<Props> = ({alarm, entity, site, filter }) => {
  const { type, createdOn } = alarm.alarms;
  const [filterState, setFilterState] = React.useState<boolean>(true);
  // React.useEffect(() => {},[filterState]);
  const oldALarm = moment(createdOn).isBefore(moment().subtract(6, 'month'));
  !oldALarm && filter(entity).subscribe((show) => {
    if(!show) setFilterState(show);
  });
  
  const show = !oldALarm && filterState;
  return show && (
    <div>
      <b>{site?.name}</b>, <b>{entity?.name}</b>: <span>{type} - {moment(createdOn).format("HH:mm DD.MM.YYYY")}</span>
    </div>
  );
}
