import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  AlarmAggregate,
  EntitySiteMap,
} from "../services/siteAlarmInfoService";
import moment from "moment";
import { Entity } from "../../entity/entity";
import { Observable } from "rxjs/internal/Observable";
import { Site } from "../../entity/site";
import { EntityAlarmItem } from "./EntityAlarmItem";

type Props = {
  alarms: AlarmAggregate[];
  entitySiteMap: EntitySiteMap;
  title: string;
  filter: (entity: Entity) => Observable<boolean>;
};

export const AlarmAggregateList: React.FC<Props> = ({
  alarms,
  entitySiteMap,
  title,
  filter,
}) => {
  const { t } = useTranslation();

  return entitySiteMap ? (
    <Accordion sx={{ marginBottom: '1em' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-label="Expand">
        <Typography variant="h3">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {alarms.sort((a, b) => moment(b.alarms.createdOn).valueOf() - moment(a.alarms.createdOn).valueOf()).map((alarm, i) => {
          const hasInfoInMap = !!entitySiteMap?.[alarm.id];
          if(!hasInfoInMap) return <></>;
          
          const { entity, site } = entitySiteMap[alarm.id];
          return <EntityAlarmItem key={"items"+i} alarm={alarm} entity={entity} site={site} filter={filter} />;
        })}
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

