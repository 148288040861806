import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ThemeProvider } from "@mui/material";
import { Subscription } from "rxjs";
import { Chanels } from "../chanels";
import { EntityService } from "../entity/entity.service";
import { SiteService } from "../entity/site.service";
import { theme } from "../react-theme";
import { Guid } from "../system/guid";
import { ServiceBusService } from "../system/service-bus.service";
import NotificationsPage from "./components/AlarmsPage";
import { AlarmsService } from "../alarms.service";
import { SignalsService } from "../signals/signals.service";

const containerElementName = "myReactComponentContainer";

@Component({
  selector: "app-my-component",
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class AlarmsComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerElementName) containerRef: ElementRef;

  siteId: Guid;

  public isLoadingEntities = true;

  private userSubscription: Subscription;

  private user: any;

  constructor(
    private bus: ServiceBusService,
    private entityService: EntityService,
    private alarmService: AlarmsService,
    private siteService: SiteService,
    private router: Router,
    private route: ActivatedRoute,
    private signalsService: SignalsService
  ) {}

  ngOnInit() {
    console.log(React); // DO NOT REMOVE - to prevent aot from removing react
    this.userSubscription = this.bus
      .chanelObservable(Chanels.USER_CHANEL)
      .subscribe((user) => {
        this.user = user;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <AlarmsContext.Provider
          value={{
            user: this.user,
            entityService: this.entityService,
            alarmService: this.alarmService,
            siteService: this.siteService,
            router: this.router,
            route: this.route,
            signalsService: this.signalsService,
          }}
        >
          <NotificationsPage />
        </AlarmsContext.Provider>
      </ThemeProvider>,
      this.containerRef.nativeElement
    );
  }
}

type NotificationsSettingsContextTypes = {
  user: any;
  entityService: EntityService;
  alarmService: AlarmsService;
  siteService: SiteService;
  signalsService: SignalsService;
  router: Router;
  route?: ActivatedRoute;
  
};

export const AlarmsContext = React.createContext<NotificationsSettingsContextTypes>(
  undefined
);
