import {
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../react-components/PageHeader";
import { AlarmsContext } from "../AlarmsReactWrapper";
import {
  AlarmAggregate,
  EntitySiteMap,
  fetchEntitySiteMap,
  fetchSiteAlarmInfo,
  getAlarmsWithLastEventNotifyAlarm,
  getOpenMissingEventsAlarms,
  SiteAlarmInfo,
} from "../services/siteAlarmInfoService";
import { SiteAlarmsList } from "./SiteAlarmsList";
import { AlarmAggregateList } from "./AlarmAggregateList";
import { take } from "rxjs/internal/operators/take";
import { RegisterSignal } from "../../signals/registersignal";
import moment from "moment";
import { Entity } from "../../entity/entity";
import { map } from "rxjs/internal/operators/map";
import { Observable, of } from "rxjs";


const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
  });

const useStyles = makeStyles(styles);

type Props = {};

const AlarmsPage: React.FC<Props> = () => {
  const { siteService, alarmService, entityService, user, signalsService} =
    React.useContext(AlarmsContext);

  const [entitySiteMap, setEntitySiteMap] = React.useState<EntitySiteMap>(null);
  const [siteAlarms, setSiteAlarms] = React.useState<SiteAlarmInfo[]>([]);
  const [missingEventAlarms, setMissingEventAlarms] = React.useState<AlarmAggregate[]>([]);
  const [notifyAlarms, setNotifyAlarms] = React.useState<AlarmAggregate[]>([]);

  React.useEffect(() => {
    fetchEntitySiteMap(
      entityService,
      siteService,
      user,
      (fetchedEntitySiteMap) => {
        setEntitySiteMap(fetchedEntitySiteMap);
      }
    );
    getOpenMissingEventsAlarms().then(setMissingEventAlarms);
    getAlarmsWithLastEventNotifyAlarm().then(setNotifyAlarms);
  }, []);

  React.useEffect(() => {
    if(entitySiteMap) {
      fetchSiteAlarmInfo(
        entitySiteMap,
        alarmService,
        (fetchedSiteAlarms) => {
          setSiteAlarms(fetchedSiteAlarms);
        }
      );
    }
    getOpenMissingEventsAlarms().then(setMissingEventAlarms);
    getAlarmsWithLastEventNotifyAlarm().then(setNotifyAlarms);
  }, [entitySiteMap]);

  const { t } = useTranslation();
  const classes = useStyles();
  const missingFilter = (e: Entity) => {
    
    return signalsService.GetAllSignalsForEntities([e.id]).pipe(take(1),map((signals) => {
      if(e.name == "Arnt"){
        // debugger;
        console.log("Arnt", e);
      }
      const temperature = signals.get(e.id)?.find((signal:RegisterSignal) => signal.isTemperatureSensor()) as RegisterSignal;
      const result = !temperature?.isAfter(moment().subtract(30, 'minute'));
      return result ;
    }));
  };

  return (
    <>
      <PageHeader title={t("alarms.pageTitle")} />
      <Typography variant="caption">Viser alarmer fra siste 6 måneder.</Typography>

      <div className={classes.root}>
        <AlarmAggregateList title={t("alarms.notifyAlarmsTitle")} alarms={notifyAlarms} entitySiteMap={entitySiteMap} filter={(e)=>of(true)}/>
        <AlarmAggregateList title={t("alarms.missingEventAlarmsTitle")} alarms={missingEventAlarms} entitySiteMap={entitySiteMap} filter={missingFilter}/>
        <SiteAlarmsList siteAlarms={siteAlarms} />
      </div>
    </>
  );
};

export default AlarmsPage;
