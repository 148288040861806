export const environment = {
  production: true,
  pushNotificationsEnabled: false,
  version: "2.0.2-101004",
  featureFlag: {
    co2: false,
  },
  dependencies: {
    domain: "app.tmesh.app",
    signal_api: {
      url: "https://tapi.bmesh.io/signal",
      domain: "tapi.bmesh.io",
    },
    entity_api: {
      url: "https://tapi.bmesh.io/entity",
      domain: "tapi.bmesh.io",
    },
    security_api: {
      url: "https://tapi.bmesh.io/security",
      domain: "tapi.bmesh.io",
    },
    notifications_api: {
      url: "https://tapi.bmesh.io/entity",
      domain: "tapi.bmesh.io",
    },
    termoobject_api: {
      url: "https://tapi.bmesh.io/entity",
      domain: "tapi.bmesh.io",
    },
    tempreports_api: {
      url: "https://tapi.bmesh.io/tempcontrol",
      domain: "tapi.bmesh.io",
    },
    checklist_api: {
      url: "https://tapi.bmesh.io/checklist",
      domain: "tapi.bmesh.io",
    },
    signature_api: {
      url: "https://tapi.bmesh.io/checklist",
      domain: "tapi.bmesh.io",
    },
    fileupload_api: {
      url: "https://tapi.bmesh.io/file",
      domain: "tapi.bmesh.io",
    },
  },
  clientId: 'ad5c663b-e9a1-41d6-b9c2-21276dbb29db',
  redirectUrl: `${window.location.origin}/login`,
  protectedResourceMap: new Map<string, Array<string>>([
    ["https://graph.microsoft.com", ["User.Read"]],
    [
      "https://tapi.bmesh.io/signal", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://tapi.bmesh.io/entity", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://tapi.bmesh.io/file", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://tapi.bmesh.io/tempcontrol", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://tapi.bmesh.io/", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://tapi.bmesh.io", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://tapi.bmesh.io/checklist", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ],
    [
      "https://tapi.bmesh.io/security", ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"],
    ]
  ]),
};
