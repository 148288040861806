import { Guid } from "../system/guid";
import { Moment } from "moment";
import moment from "moment";

export class RegisterSignal {
  constructor(
    private id: Guid,
    private type: string,
    public sourceId: string,
    public timestamp: Moment,
    private unit: string,
    private hubId: string,
    private value: string,
    private lastValue: Moment,
    private unitCode: string
  ) {
    this.lastValue = moment(this.lastValue);
  }
  public get Value(): string {
    return `${this.value} ${this.unit}`;
  }

  public getValue(): number {
    return Number(this.value);
  }

  public isDoorClickSensor(): boolean {
    return this.unit == "DoorOC";
  }
  public isTemperatureSensor(): boolean {
    return this.unit == "TempC";
  }
  public toString(): string {
    return `Source: ${this.sourceId}, Value: ${this.value}`;
  }
  public isBefore(time: Moment): boolean {
    return this.lastValue.isBefore(time);
  }
  public isAfter(time: Moment): boolean {
    return this.lastValue.isAfter(time);
  }
  public static compare(a: RegisterSignal, b: RegisterSignal): number {
    if (a && ((a.lastValue && !b) || (a.lastValue && !b.lastValue))) return 1;
    if (b && ((b.lastValue && !a) || (b.lastValue && !a.lastValue))) return -1;
    if ((!b || !b.lastValue) && (!a || !a.lastValue)) return 0;
    return a.lastValue.diff(b.lastValue);
  }
  public static compareDesc(a: RegisterSignal, b: RegisterSignal): number {
    if (a && ((a.lastValue && !b) || (a.lastValue && !b.lastValue))) return -1;
    if (b && ((b.lastValue && !a) || (b.lastValue && !a.lastValue))) return 1;
    if ((!b || !b.lastValue) && (!a || !a.lastValue)) return 0;
    return b.lastValue.diff(a.lastValue);
  }
}
